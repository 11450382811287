export const onClientEntry = () => {
  // eslint-disable-next-line
  if (!('IntersectionObserver' in window)) {
    // eslint-disable-next-line no-unused-expressions
    import('intersection-observer');
  }
};

// eslint-disable-next-line
export { default as wrapRootElement } from './src/redux/reduxWrapper';
