import { combineReducers } from 'redux';
import common from './common';
import auth from './auth';
import deal from './deal';
import customer from './customer';
import brand from './brand';
import file from './file';
import filters from './filters';

export default combineReducers({
  common,
  auth,
  deal,
  customer,
  brand,
  file,
  filters,
});
