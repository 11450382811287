import ACTIONS from '../../constants/actions';
import { DEFAULT_LOCATION } from '../../constants/filters';

const initState = {
  filteredSort: 0,
  filteredLocation: [DEFAULT_LOCATION],
  filteredWard: [],
  wardList: [],
  filteredItemsString: '',
  filteredSortString: '',
  filteredLocationString: '',
  filteredWardString: '',
};

export default (state, action) => {
  const newState = state || initState;
  switch (action.type) {
    case ACTIONS.CUSTOMER.SET_FILTERED_SORT:
      return {
        ...newState,
        filteredSort: action.payload,
      };
    case ACTIONS.CUSTOMER.SET_FILTERED_LOCATION:
      return {
        ...newState,
        filteredLocation: action.payload,
      };
    case ACTIONS.CUSTOMER.SET_FILTERED_WARD:
      return {
        ...newState,
        filteredWard: action.payload,
      };
    case ACTIONS.CUSTOMER.SET_WARD_LIST:
      return {
        ...newState,
        wardList: action.payload,
      };
    case ACTIONS.CUSTOMER.FILTERED_ITEMS_STRING:
      return {
        ...newState,
        filteredItemsString: action.payload,
      };
    case ACTIONS.CUSTOMER.FILTERED_SORT_STRING:
      return {
        ...newState,
        filteredSortString: action.payload,
      };
    case ACTIONS.CUSTOMER.FILTERED_LOCATION_STRING:
      return {
        ...newState,
        filteredLocationString: action.payload,
      };
    case ACTIONS.CUSTOMER.FILTERED_WARD_STRING:
      return {
        ...newState,
        filteredWardString: action.payload,
      };
    default:
      return newState;
  }
};
