import ACTIONS from '../../constants/actions';

const initState = {
  fileUpload: [],
};

export default (state = initState, action) => {
  switch (action.type) {
    case ACTIONS.FILE.UPLOAD:
      return {
        ...state,
        fileUpload: action.payload,
      };
    case ACTIONS.FILE.RESET:
      return {
        ...state,
        fileUpload: [],
      };
    default:
      return state;
  }
};
