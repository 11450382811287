export default {
  COMMON: {
    LOADING: 'COMMON.SET_LOADING',
    ERROR: {
      SET: 'COMMON.SET_ERROR',
      RESET: 'COMMON.RESET_ERROR',
    },
  },
  AUTH: {
    LOGIN: 'AUTH.LOGIN_USER',
    LOGOUT: 'AUTH.LOGOUT_USER',
  },
  DEAL: {
    ROOT: 'DEAL',
    get CREATE() {
      return `${this.ROOT}.CREATE_DEAL`;
    },
    get UPDATE() {
      return `${this.ROOT}.UPDATE_DEAL`;
    },
    get DELETE() {
      return `${this.ROOT}.DELETE_DEAL`;
    },
    get RESET() {
      return `${this.ROOT}.RESET_DEAL`;
    },
    get SET_DEAL_LIST() {
      return `${this.ROOT}.SET_DEAL_LIST`;
    },
    get FETCH_DETAIL() {
      return `${this.ROOT}.FETCH_DETAIL`;
    },
    get ADMIN() {
      return `${this.ROOT}.ADMIN`;
    },
    get ADMIN_SEARCH() {
      return `${this.ROOT}.ADMIN_SEARCH`;
    },
    get SET_ADMIN_SEARCH_TEXT() {
      return `${this.ROOT}.SET_ADMIN_SEARCH_TEXT`;
    },
    get BLOCK_SIDE_BAR() {
      return `${this.ROOT}.BLOCK_SIDE_BAR`;
    },
    get CONFIGS() {
      return `${this.ROOT}.CONFIGS`;
    },
  },
  BRAND: {
    ROOT: 'BRAND',
    get CREATE_BRAND() {
      return `${this.ROOT}.CREATE_BRAND`;
    },
    get UPDATE_BRAND() {
      return `${this.ROOT}.UPDATE_BRAND`;
    },
    get UPDATE_BRANCH() {
      return `${this.ROOT}.UPDATE_BRANCH`;
    },
    get DELETE_BRAND() {
      return `${this.ROOT}.DELETE_BRAND`;
    },
    get DELETE_BRANCH() {
      return `${this.ROOT}.DELETE_BRANCH`;
    },
    get GET_BRAND_BY_ID() {
      return `${this.ROOT}.GET_BRAND_BY_ID`;
    },
    get GET_BRANDS() {
      return `${this.ROOT}.GET_BRANDS`;
    },
    get CREATE_BRANCH() {
      return `${this.ROOT}.CREATE_BRANCH`;
    },
    get GET_BRANCH_BY_ID() {
      return `${this.ROOT}.GET_BRANCH_BY_ID`;
    },
    get GET_BRANCHES() {
      return `${this.ROOT}.GET_BRANCHES`;
    },
    get RESET_DATA() {
      return `${this.ROOT}.RESET_DATA`;
    },
  },
  CUSTOMER: {
    ROOT: 'CUSTOMER',
    get SEARCH_DEAL() {
      return `${this.ROOT}.SEARCH_DEAL`;
    },
    get DEAL_DETAIL() {
      return `${this.ROOT}.DEAL_DETAIL`;
    },
    get DEAL_IN_SAME_CATEGORY() {
      return `${this.ROOT}.DEAL_IN_SAME_CATEGORY`;
    },
    get BANNER_DEALS() {
      return `${this.ROOT}.BANNER_DEALS`;
    },
    get SET_SEARCH_BAR_DEALS() {
      return `${this.ROOT}.SET_SEARCH_BAR_DEALS`;
    },
    get FLYING_SEARCH_RESULTS() {
      return `${this.ROOT}.FLYING_SEARCH_RESULTS`;
    },
    get USER_FILTER() {
      return `${this.ROOT}.USER_FILTER`;
    },
    get URL_FILTER_DEALS() {
      return `${this.ROOT}.URL_FILTER_DEALS`;
    },
    get LOCATION_FILTER_DEALS() {
      return `${this.ROOT}.LOCATION_FILTER_DEALS`;
    },
    get PAGE_NUMBER() {
      return `${this.ROOT}.PAGE_NUMBER`;
    },
    get SEARCH_TEXT() {
      return `${this.ROOT}.SEARCH_TEXT`;
    },
    get SET_FILTERED_LOCATION() {
      return `${this.ROOT}.SET_FILTERED_LOCATION`;
    },
    get SET_FILTERED_WARD() {
      return `${this.ROOT}.SET_FILTERED_WARD`;
    },
    get SET_WARD_LIST() {
      return `${this.ROOT}.SET_WARD_LIST`;
    },
    get SET_FILTERED_SORT() {
      return `${this.ROOT}.SET_FILTERED_SORT`;
    },
    get FILTER_DEALS() {
      return `${this.ROOT}.FILTER_DEALS`;
    },
    get FILTERED_ITEMS_STRING() {
      return `${this.ROOT}.FILTERED_ITEMS_STRING`;
    },
    get FILTERED_SORT_STRING() {
      return `${this.ROOT}.FILTERED_SORT_STRING`;
    },
    get FILTERED_LOCATION_STRING() {
      return `${this.ROOT}.FILTERED_LOCATION_STRING`;
    },
    get FILTERED_WARD_STRING() {
      return `${this.ROOT}.FILTERED_WARD_STRING`;
    },
    get QUICK_SEARCH() {
      return `${this.ROOT}.QUICK_SEARCH`;
    },
    get CLEAR_QUICK_SEARCH() {
      return `${this.ROOT}.CLEAR_QUICK_SEARCH`;
    },
    get SUBMIT_QUICK_SEARCH() {
      return `${this.ROOT}.SUBMIT_QUICK_SEARCH`;
    },
  },
  SETTINGS: 'SETTINGS',
  FILE: {
    ROOT: 'FILE',
    get UPLOAD() {
      return `${this.ROOT}.UPLOAD`;
    },
    get RESET() {
      return `${this.ROOT}.RESET`;
    },
  },
};
