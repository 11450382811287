import { createMuiTheme } from '@material-ui/core';
import red from '@material-ui/core/colors/red';
import grey from '@material-ui/core/colors/grey';

const theme = createMuiTheme({
  typography: {
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    fontSize: 16,
    subtitle1: {
      fontWeight: 700,
      fontSize: 20,
      textShadow: `1px 1px 5px ${grey[300]}`,
    },
    subtitle2: {
      fontWeight: 500,
      fontSize: 18,
      textShadow: `1px 1px 5px ${grey[300]}`,
    },
    body1: {
      fontSize: 16,
    },
    body2: {
      fontSize: 14,
    },
    body3: {
      fontSize: 12,
    },
  },
  palette: {
    background: {
      default: '#F7F7F7',
      paper: '#FFFFFF',
    },
    primary: {
      lighter: red[50],
      light: red[100],
      main: '#e21838',
      dark: red[900],
      contrastText: grey[50],
    },
    secondary: {
      lighter: grey[100],
      light: grey[300],
      main: grey[500],
      dark: grey[900],
    },
    text: {
      disabled: grey[300],
      primary: grey[900],
      secondary: grey[500],
      error: red[700],
    },
    action: {
      selected: grey[200],
      hover: grey[50],
    },
  },
  shape: {
    borderRadius: 8,
    rectRadius: 4,
    round: 25,
  },
  transitions: {
    easing: {
      custom: `all 100ms ease`,
    },
  },
});

export default theme;
