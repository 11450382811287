import All from '../svgs/f_ic_all.svg';
import Dining from '../svgs/f_ic_dining.svg';
import Fashion from '../svgs/f_ic_shopping.svg';
import Beauty from '../svgs/f_ic_beauti.svg';
import Health from '../svgs/f_ic_health.svg';
import Entertain from '../svgs/f_ic_entertain.svg';
import Travel from '../svgs/f_ic_travel.svg';
import Edu from '../svgs/f_ic_edu.svg';
import Electronics from '../svgs/f_ic_electronics.svg';
import MotorBike from '../svgs/f_ic_motorbike.svg';
import Phone from '../svgs/f_ic_phone.svg';
import Computer from '../svgs/f_ic_computer.svg';
import Television from '../svgs/f_ic_tv.svg';
import Audio from '../svgs/f_ic_audio.svg';
import Other from '../svgs/f_ic_others.svg';
import AllBW from '../svgs/f_ic_all-bw.svg';
import DiningBW from '../svgs/f_ic_dining-bw.svg';
import FashionBW from '../svgs/f_ic_shopping-bw.svg';
import BeautyBW from '../svgs/f_ic_beauti-bw.svg';
import HealthBW from '../svgs/f_ic_health-bw.svg';
import EntertainBW from '../svgs/f_ic_entertain-bw.svg';
import TravelBW from '../svgs/f_ic_travel-bw.svg';
import EduBW from '../svgs/f_ic_edu-bw.svg';
import ElectronicsBW from '../svgs/f_ic_electronics-bw.svg';
import MotorBikeBW from '../svgs/f_ic_motorbike-bw.svg';
import PhoneBW from '../svgs/f_ic_phone-bw.svg';
import ComputerBW from '../svgs/f_ic_computer-bw.svg';
import TelevisionBW from '../svgs/f_ic_tv-bw.svg';
import AudioBW from '../svgs/f_ic_audio-bw.svg';
import OtherBW from '../svgs/f_ic_others-bw.svg';
import Dummy from '../svgs/ic_arrow_down_none.svg';

export const DEFAULT_ID = '0';

export const DEAL_SORT = [
  { key: 0, label: 'Nổi bật' },
  { key: 1, label: 'Mới nhất' },
  { key: 2, label: 'Sắp hết hạn' },
];

export const CATEGORY_BW = [
  AllBW,
  DiningBW,
  FashionBW,
  BeautyBW,
  HealthBW,
  EntertainBW,
  TravelBW,
  EduBW,
  ElectronicsBW,
  MotorBikeBW,
  PhoneBW,
  ComputerBW,
  TelevisionBW,
  AudioBW,
  OtherBW,
  Dummy,
];

export const CATEGORY_ICONS = [
  All,
  Dining,
  Fashion,
  Beauty,
  Health,
  Entertain,
  Travel,
  Edu,
  Electronics,
  MotorBike,
  Phone,
  Computer,
  Television,
  Audio,
  Other,
  Dummy,
];

export const CATEGORY_CHUNK = 4;

export const FILTER_KEY = {
  CATEGORY: 'category',
  TYPE: 'type',
  BRAND: 'brand',
  METHOD: 'method',
  LOCATION: 'location',
  DISTRICT: 'district',
  WARD: 'ward',
  PRIORITY: 'priority',
  BRANCH: 'branch',
};

export const FILTERS_REGEXP = {
  CATEGORY: /category=([\w,]+)/,
  TYPE: /type=([\w,]+)/,
  BRAND: /brand=([\w,]+)/,
  METHOD: /method=([\w,]+)/,
  LOCATION: /location=([\w,]+)/,
  DISTRICT: /district=([\w,]+)/,
  DELIMITER: ',',
  SORT: /sort=(\d)/,
};

export const FILTER_LABELS = {
  ALL: 'Tất cả',
  CATEGORY: 'Danh mục',
  TYPE: 'Loại ưu đãi',
  FILTERED_TYPES: 'loại',
  BRAND: 'Thương hiệu',
  FILTERED_BRANDS: 'hãng',
  METHOD: 'Phương thức áp dụng',
  FILTERED_METHODS: 'cách',
  BRAND_SEARCH: 'Tìm thương hiệu',
  LIST_TITLE: 'Lọc ưu đãi',
  CTA: {
    ALL: 'CHỌN TẤT CẢ',
    DISMISS_ALL: 'XÓA BỘ LỌC',
    FILTER: 'LỌC ƯU ĐÃI',
    CLOSE_LIST: 'ĐÓNG',
  },
};

export const DEFAULT_FILTER_ITEM = {
  name: FILTER_LABELS.ALL,
  id: DEFAULT_ID,
};

export const DEFAULT_LOCATION = {
  name: 'Toàn Quốc',
  id: DEFAULT_ID,
  wards: [],
};

export const DEFAULT_WARD = {
  name: 'Quận / Huyện',
  id: DEFAULT_ID,
};

export default [
  {
    key: FILTER_KEY.CATEGORY,
    label: 'Danh mục',
    searchString: 'categories=',
    keyName: '',
    icon: true,
  },
  {
    key: FILTER_KEY.TYPE,
    label: 'Loại ưu đãi',
    searchString: 'types=',
    keyName: 'loại',
    icon: false,
  },
  {
    key: FILTER_KEY.BRAND,
    label: 'Thương hiệu',
    searchString: 'brands=',
    keyName: 'hãng',
    icon: false,
  },
  {
    key: FILTER_KEY.METHOD,
    label: 'Phương thức áp dụng',
    searchString: 'methods=',
    keyName: 'cách',
    icon: false,
  },
];
