import get from 'lodash/get';
import ACTIONS from '../../constants/actions';

const initState = {
  createdBrand: '',
  deletedBrand: '',
  createdBranch: '',
  brands: {},
  branches: {},
};

export default (state = initState, action) => {
  switch (action.type) {
    case ACTIONS.BRAND.CREATE_BRAND:
    case ACTIONS.BRAND.UPDATE_BRAND:
      return {
        ...state,
        createdBrand: get(action.payload, 'id'),
      };
    case ACTIONS.BRAND.CREATE_BRANCH:
    case ACTIONS.BRAND.UPDATE_BRANCH:
      return {
        ...state,
        createdBranch: get(action.payload, 'id'),
      };
    case ACTIONS.BRAND.GET_BRANDS:
      return {
        ...state,
        brands: action.payload,
      };
    case ACTIONS.BRAND.GET_BRANCHES:
      return {
        ...state,
        branches: action.payload,
      };
    case ACTIONS.BRAND.RESET_DATA:
      return {
        ...state,
        createdBrand: '',
        createdBranch: '',
        deletedBrand: '',
        deletedBranch: '',
      };
    case ACTIONS.BRAND.DELETE_BRAND:
      return {
        ...state,
        deletedBrand: get(action.payload, 'id'),
      };
    case ACTIONS.BRAND.DELETE_BRANCH:
      return {
        ...state,
        deletedBranch: action.payload,
      };
    default:
      return state;
  }
};
