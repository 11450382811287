import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import ACTIONS from '../../constants/actions';

const config = {
  key: 'hczone-customer',
  storage,
  stateReconciler: autoMergeLevel2,
  blacklist: ['dealDetail', 'searchBarDeals'],
};

const initState = {
  settings: {},
  dealDetail: {},
  pageNumber: 1,
  searchText: {},
  searchDealResults: [],
  dealCount: 0,
  bannerDeal: [],
  searchBarDeals: [],
  submittedSearchBarDeals: [],
};

const reducer = (state, action) => {
  const newState = state || initState;
  switch (action.type) {
    case ACTIONS.CUSTOMER.SETTINGS:
      return {
        ...newState,
        settings: action.payload,
      };
    case ACTIONS.CUSTOMER.DEAL_DETAIL:
      return {
        ...newState,
        dealDetail: action.payload,
      };
    case ACTIONS.CUSTOMER.DEAL_IN_SAME_CATEGORY:
      return {
        ...newState,
        dealCategory: action.payload.deals,
      };
    case ACTIONS.CUSTOMER.SEARCH_DEAL:
    case ACTIONS.CUSTOMER.USER_FILTER:
    case ACTIONS.CUSTOMER.FILTER_DEALS:
    case ACTIONS.CUSTOMER.URL_FILTER_DEALS:
    case ACTIONS.CUSTOMER.LOCATION_FILTER_DEALS:
      return {
        ...newState,
        searchDealResults: action.payload.deals,
        dealCount: action.payload.count,
      };
    case ACTIONS.CUSTOMER.BANNER_DEALS:
      return {
        ...newState,
        bannerDeals: action.payload.deals,
      };
    case ACTIONS.CUSTOMER.SET_SEARCH_BAR_DEALS:
    case ACTIONS.CUSTOMER.FLYING_SEARCH_RESULTS:
    case ACTIONS.CUSTOMER.QUICK_SEARCH:
      return {
        ...newState,
        searchBarDeals: action.payload.deals,
      };
    case ACTIONS.CUSTOMER.PAGE_NUMBER:
      return {
        ...newState,
        pageNumber: action.payload,
      };
    case ACTIONS.CUSTOMER.SUBMIT_QUICK_SEARCH:
      return {
        ...newState,
        submittedSearchBarDeals: action.payload,
      };
    case ACTIONS.CUSTOMER.SEARCH_TEXT:
      return {
        ...newState,
        searchText: action.payload,
      };
    case ACTIONS.CUSTOMER.RESET_FILTERS:
      return {
        ...newState,
        searchDealResults: [],
      };
    case ACTIONS.CUSTOMER.CLEAR_QUICK_SEARCH:
      return {
        ...newState,
        searchBarDeals: [],
      };
    default:
      return newState;
  }
};

export default persistReducer(config, reducer);
