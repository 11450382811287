import ACTIONS from '../../constants/actions';

const initState = {
  isLoading: false,
  isError: false,
  errMsg: '',
};

export default (state = initState, action) => {
  switch (action.type) {
    case ACTIONS.COMMON.LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    case ACTIONS.COMMON.ERROR.SET:
      return {
        ...state,
        isError: true,
        errMsg: action.payload,
      };
    case ACTIONS.COMMON.ERROR.RESET:
      return {
        ...state,
        isError: false,
        errMsg: '',
      };
    case ACTIONS.SETTINGS:
      return {
        ...state,
        settings: action.payload,
      };
    default:
      return state;
  }
};
