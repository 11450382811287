import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import get from 'lodash/get';
import ACTIONS from '../../constants/actions';

const config = {
  key: 'hczone-deal',
  storage,
  stateReconciler: autoMergeLevel2,
  blacklist: [],
};

const initState = {
  createdId: '',
  adminSearchText: '',
  dealDetail: {},
  dealAdmin: {},
  deletedDeal: {},
  configs: {},
};

const reducer = (state = initState, action) => {
  switch (action.type) {
    case ACTIONS.DEAL.CREATE:
    case ACTIONS.DEAL.UPDATE:
      return {
        ...state,
        createdId: get(action.payload, 'id', ''),
      };
    case ACTIONS.DEAL.FETCH_DETAIL:
    case ACTIONS.DEAL.SET_ADMIN_DETAIL:
      return {
        ...state,
        dealDetail: action.payload,
      };
    case ACTIONS.DEAL.DELETE:
      return {
        ...state,
        deletedDeal: action.payload,
      };
    case ACTIONS.DEAL.RESET:
      return {
        ...state,
        createdId: '',
        dealDetail: {},
        deletedDeal: {},
      };
    case ACTIONS.DEAL.ADMIN:
    case ACTIONS.DEAL.ADMIN_SEARCH:
      return {
        ...state,
        dealAdmin: action.payload,
      };
    case ACTIONS.DEAL.SET_ADMIN_SEARCH_TEXT:
      return {
        ...state,
        adminSearchText: action.payload,
      };
    case ACTIONS.AUTH.LOGOUT:
      return {
        ...state,
        dealAdmin: {},
      };
    case ACTIONS.DEAL.CONFIGS:
      return {
        ...state,
        configs: action.payload,
      };
    default:
      return state;
  }
};

export default persistReducer(config, reducer);
