// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-chi-tiet-tao-uu-dai-js": () => import("../src/pages/chi-tiet-tao-uu-dai.js" /* webpackChunkName: "component---src-pages-chi-tiet-tao-uu-dai-js" */),
  "component---src-pages-chitiet-js": () => import("../src/pages/chitiet.js" /* webpackChunkName: "component---src-pages-chitiet-js" */),
  "component---src-pages-ket-qua-tim-kiem-js": () => import("../src/pages/ket-qua-tim-kiem.js" /* webpackChunkName: "component---src-pages-ket-qua-tim-kiem-js" */),
  "component---src-pages-tao-uu-dai-js": () => import("../src/pages/tao-uu-dai.js" /* webpackChunkName: "component---src-pages-tao-uu-dai-js" */)
}

