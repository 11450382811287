import sessionStorage from 'redux-persist/lib/storage/session';
import get from 'lodash/get';
import { persistReducer } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import ACTIONS from '../../constants/actions';

const config = {
  key: 'hczone-authentication',
  storage: sessionStorage,
  stateReconciler: autoMergeLevel2,
  blacklist: [],
};

const initState = {
  isAuth: false,
  userDetail: {},
};

const reducer = (state = initState, action) => {
  if (action.type === ACTIONS.AUTH.LOGIN) {
    const userName = get(action.payload, 'user.username');
    const userConfirm = get(action.payload, 'user.confirmed');
    const userBlocked = get(action.payload, 'user.blocked');
    const userEmail = get(action.payload, 'user.email');
    const userId = get(action.payload, 'user.id');
    const isAuth = userConfirm && !userBlocked;
    const userJwt = get(action.payload, 'jwt');
    return {
      ...state,
      isAuth,
      userDetail: {
        userName,
        userEmail,
        userId,
        userJwt,
      },
    };
  }
  if (action.type === ACTIONS.AUTH.LOGOUT) {
    return {
      ...state,
      isAuth: false,
      userDetail: {},
    };
  }
  return state;
};

export default persistReducer(config, reducer);
